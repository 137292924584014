import {getValueFuzzy} from "~/utils/getValue"

export const displayProjects = (projects: any[]) => {
    if(projects.length === 0) return []

    const allKeys = new Set<string>()
    for(let i=0; i<projects.length; i++){
        for(let key in projects[i]){
            allKeys.add(key)
        }
    }

    return projects.map(x=>{
        const cityValue = getValueFuzzy(x, "项目城市")
        const cityCount = (cityValue||"").split(",").length
        const projectYear = getValueFuzzy(x, "签约时间") as string

        let projectYearDisplay = ""
        if (projectYear.match(/^\d{2}-\d{2}-\d{2}$/)) {
            projectYearDisplay = projectYear.split("-").length == 3 
                                        ? "20" + projectYear.split("-")[2] 
                                        : ""
        }else if (projectYear.match(/^\d{4}\/\d{2}\/\d{2}$/)){
            projectYearDisplay = projectYear.split("/")[0]
        }

        return {
            // ...x,
            "1.部门": getValueFuzzy(x, "执行部门"),
            "2.年份": projectYearDisplay,
            "3.城市": cityCount > 1 ? "多城市" : cityValue,
            "4.项目名称": getValueFuzzy(x, "项目名称"),
            "5.客户单位": getValueFuzzy(x, "客户单位"),
            // clickable:true
    }}).sort((a,b)=>{
        if(a["2.年份"] < b["2.年份"]) return 1
        if(a["2.年份"] > b["2.年份"]) return -1
        if(a["3.城市"] != b["3.城市"]) return pinyinSort(a["3.城市"], b["3.城市"])
        if(a["4.项目名称"] != b["4.项目名称"]) return pinyinSort(a["4.项目名称"], b["4.项目名称"])
        return 0
    })
}